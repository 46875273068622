import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { IndentedTitle } from "../../components/global/common/text"
import {
  LineWrapper,
  ShadowBoxContainer,
} from "../../components/global/common/containers"
import { greenBox } from "../../components/global/common/ornaments"

const SectionContainer = styled.section`
  overflow: hidden;

  background-color: var(--background-color);
`

const SectionWrapper = styled(LineWrapper)`
  position: relative;
  z-index: 2;

  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
`

const SectionContent = styled.div`
  display: flex;

  @media ${minD.tablet} {
    align-items: var(--content-align, flex-end);
  }

  @media ${maxD.tablet} {
    flex-direction: column;
  }
`

const SectionImage = styled(GatsbyImage)`
  width: 100%;
  flex-shrink: 0;
  height: 700px;

  ${greenBox}

  &::after {
    left: -45px;
    bottom: 20px;
  }

  @media ${minD.laptop} {
    width: calc(100% - 700px);
  }

  @media ${minD.desktop} {
    width: calc(100% - 800px);
  }

  @media ${maxD.laptop} {
    display: none;
  }

  @media ${maxD.tablet} {
    display: block;
  }

  @media ${maxD.tablet} {
    height: 400px;
  }
`

const SectionWithIndentedTitle = ({
  title,
  image,
  children,
  containerStyle,
  titleStyle,
  contentStyle,
  wrapperStyle,
  imageStyle = { overflow: "visible" },
}) => {
  return (
    <SectionContainer style={containerStyle}>
      <SectionWrapper style={wrapperStyle}>
        <ShadowBoxContainer>
          <IndentedTitle
            style={titleStyle}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <SectionContent style={contentStyle}>
            {image.url && (
              <SectionImage
                image={image.url}
                alt={image.alt}
                style={imageStyle}
              />
            )}
            {children}
          </SectionContent>
        </ShadowBoxContainer>
      </SectionWrapper>
    </SectionContainer>
  )
}

export default SectionWithIndentedTitle
