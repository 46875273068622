import React from "react"

import MasonryBoxes from "../global/atoms/MasonryBoxes"
import SectionWithIndentedTitle from "../global/SectionWithIndentedTitle"

const Reasons = ({ data }) => {
  const title = data.indentedSectionTitle

  const image = {
    url: data.indentedSectionImage?.localFile.childImageSharp.gatsbyImageData,
    alt: data.indentedSectionImage?.altText,
  }

  const button = {
    title: data.indentedSectionButton?.title,
    url: data.indentedSectionButton?.url,
    target: data.indentedSectionButton?.target,
  }

  const boxes = data.reasonsList

  return (
    <SectionWithIndentedTitle title={title} image={image}>
      <MasonryBoxes
        boxes={boxes}
        button={button}
        boxStyles={{ "--box-background-color": "#fff" }}
      />
    </SectionWithIndentedTitle>
  )
}

export default Reasons
